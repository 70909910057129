import React from 'react'
import { css } from '@emotion/react'

import theme from 'styles/theme'
import SvgLogoGoogle from 'svgs/logo-google.svg'

export default function GoogleReview({ data }) {
  return (
    <a
      css={googleReview}
      href={data.GOOGLE_REVIEW}
      target='_blank'
      rel='noreferrer'
    >
      <span>Review us on</span>
      <SvgLogoGoogle />
    </a>
  )
}

const googleReview = css`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding-left: ${theme.gutter};
  margin-block-start: 2rem;

  @media (${theme.max960}) {
    margin-bottom: 1rem;
  }

  @media (${theme.min960}) {
    margin-bottom: 3rem;
    padding-left: 0;
    align-self: flex-end;
  }
`
