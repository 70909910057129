import React from 'react'
import { useInView } from 'react-intersection-observer'

import GlobalStyles from 'styles/GlobalStyles'
import { Main } from 'styles/CssHelpers'
import WhatsAppLink from 'components/common/WhatsAppLink'
import Header from 'components/Header'
import Footer from 'components/Footer'

export default function Layout({ children }) {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <>
      <GlobalStyles />
      <Header />
      <Main>{children}</Main>
      <div style={{ width: '100%', overflow: 'hidden' }} ref={ref}>
        {inView && <Footer />}
      </div>
      <WhatsAppLink />
    </>
  )
}
