import React from 'react'

export default function SvgBristolLogo({ className }) {
  return (
    <svg
      width='500'
      height='150'
      viewBox='0 0 500 150'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M456.313 80.7803C452.939 86.1347 446.97 89.6847 440.163 89.6847H360.094V110.009H428.706C446.159 110.009 460.87 98.2932 465.403 82.3036C463.111 81.3242 460.589 80.7803 457.94 80.7803H456.313Z'
        fill='#ED2424'
      />
      <path
        d='M458.72 45.83C451.738 36.9386 440.894 31.2222 428.707 31.2222H360.095V51.5592H440.162C444.824 51.5592 449.093 53.2405 452.404 56.0261C455.501 53.439 457.762 49.889 458.72 45.83Z'
        fill='#ED2424'
      />
      <path
        d='M93.7682 139.23H98.4688V119.803H93.7682V139.23Z'
        fill='#25358E'
      />
      <path
        d='M115.693 125.493C116.728 126.665 117.246 128.277 117.246 130.327V139.232H112.545V130.879C112.545 129.853 112.275 129.05 111.735 128.473C111.194 127.896 110.474 127.608 109.576 127.608C108.622 127.608 107.867 127.909 107.308 128.516C106.749 129.12 106.468 129.98 106.468 131.097V139.232H101.769V123.9H106.468V126.702C106.909 125.787 107.563 125.063 108.435 124.53C109.304 124.001 110.328 123.733 111.501 123.733C113.259 123.733 114.658 124.321 115.693 125.493Z'
        fill='#25358E'
      />
      <path
        d='M125.314 128.817C124.7 129.477 124.393 130.392 124.393 131.566C124.393 132.738 124.7 133.653 125.314 134.313C125.928 134.973 126.739 135.303 127.746 135.303C128.737 135.303 129.548 134.963 130.18 134.285C130.811 133.607 131.129 132.701 131.129 131.566C131.129 130.41 130.811 129.501 130.18 128.832C129.548 128.163 128.737 127.827 127.746 127.827C126.739 127.827 125.928 128.159 125.314 128.817ZM129.259 124.503C130.101 125.034 130.725 125.758 131.129 126.675V123.9H135.802V139.149C135.802 140.579 135.528 141.865 134.977 143.01C134.427 144.154 133.585 145.066 132.447 145.744C131.311 146.422 129.901 146.76 128.215 146.76C125.906 146.76 124.059 146.216 122.674 145.124C121.29 144.035 120.461 142.558 120.187 140.687H124.833C124.98 141.31 125.314 141.795 125.836 142.144C126.359 142.491 127.041 142.666 127.884 142.666C128.855 142.666 129.64 142.387 130.236 141.828C130.831 141.269 131.129 140.376 131.129 139.149V136.455C130.725 137.372 130.101 138.096 129.259 138.627C128.416 139.158 127.408 139.423 126.234 139.423C124.971 139.423 123.838 139.109 122.84 138.475C121.84 137.844 121.053 136.933 120.476 135.742C119.899 134.552 119.61 133.159 119.61 131.566C119.61 129.954 119.899 128.556 120.476 127.375C121.053 126.193 121.84 125.285 122.84 124.655C123.838 124.021 124.971 123.707 126.234 123.707C127.408 123.707 128.416 123.972 129.259 124.503Z'
        fill='#25358E'
      />
      <path
        d='M139.102 139.23H143.802V118.897H139.102V139.23Z'
        fill='#25358E'
      />
      <path
        d='M157.96 119.969L151.335 122.8V119.612L157.96 116.424V119.969ZM156.104 128.171C155.546 127.668 154.853 127.416 154.028 127.416C153.168 127.416 152.451 127.673 151.884 128.186C151.316 128.697 150.995 129.45 150.921 130.439H156.916C156.933 129.432 156.662 128.675 156.104 128.171ZM161.534 132.418H150.894C150.949 133.57 151.243 134.4 151.774 134.904C152.305 135.407 152.984 135.659 153.809 135.659C154.506 135.659 155.081 135.486 155.541 135.137C155.999 134.79 156.302 134.34 156.447 133.79H161.424C161.221 134.871 160.782 135.839 160.106 136.69C159.425 137.542 158.565 138.211 157.521 138.694C156.475 139.181 155.311 139.424 154.028 139.424C152.526 139.424 151.191 139.11 150.03 138.476C148.864 137.846 147.954 136.932 147.294 135.742C146.632 134.551 146.303 133.158 146.303 131.565C146.303 129.954 146.629 128.555 147.279 127.374C147.93 126.192 148.842 125.287 150.015 124.654C151.188 124.022 152.526 123.706 154.028 123.706C155.55 123.706 156.888 124.019 158.041 124.64C159.197 125.263 160.091 126.139 160.721 127.265C161.356 128.39 161.672 129.687 161.672 131.154C161.672 131.538 161.626 131.958 161.534 132.418Z'
        fill='#25358E'
      />
      <path
        d='M174.413 125.162C175.577 126.134 176.305 127.417 176.599 129.01H172.2C172.073 128.387 171.782 127.896 171.334 127.54C170.885 127.181 170.321 127.003 169.644 127.003C169.111 127.003 168.709 127.119 168.435 127.347C168.159 127.576 168.021 127.902 168.021 128.322C168.021 128.798 168.273 129.157 168.778 129.394C169.282 129.634 170.074 129.871 171.155 130.108C172.328 130.384 173.29 130.663 174.042 130.946C174.793 131.231 175.444 131.692 175.994 132.335C176.544 132.975 176.819 133.837 176.819 134.919C176.819 135.797 176.58 136.576 176.104 137.252C175.626 137.93 174.941 138.461 174.042 138.846C173.144 139.231 172.08 139.424 170.852 139.424C168.782 139.424 167.124 138.967 165.877 138.05C164.631 137.135 163.87 135.825 163.596 134.121H168.131C168.205 134.779 168.484 135.285 168.97 135.632C169.455 135.981 170.084 136.154 170.852 136.154C171.385 136.154 171.788 136.031 172.063 135.782C172.337 135.534 172.475 135.211 172.475 134.809C172.475 134.276 172.223 133.895 171.72 133.668C171.214 133.438 170.394 133.197 169.258 132.94C168.122 132.703 167.186 132.444 166.455 132.17C165.721 131.894 165.089 131.45 164.558 130.836C164.026 130.222 163.76 129.384 163.76 128.322C163.76 126.948 164.284 125.836 165.328 124.984C166.372 124.131 167.846 123.707 169.753 123.707C171.696 123.707 173.249 124.194 174.413 125.162Z'
        fill='#25358E'
      />
      <path
        d='M194.962 126.152C194.962 124.448 194.017 123.598 192.131 123.598H189.74V128.652H192.131C194.017 128.652 194.962 127.818 194.962 126.152ZM189.74 132.417V139.231H185.039V119.803H192.653C194.962 119.803 196.722 120.378 197.931 121.521C199.141 122.668 199.746 124.211 199.746 126.152C199.746 127.362 199.475 128.437 198.935 129.38C198.394 130.324 197.591 131.067 196.529 131.607C195.466 132.147 194.174 132.417 192.653 132.417H189.74Z'
        fill='#25358E'
      />
      <path
        d='M209.078 124.558C210.005 124.009 211.009 123.733 212.09 123.733V128.764H210.742C209.496 128.764 208.529 129.027 207.841 129.56C207.154 130.091 206.812 130.987 206.812 132.252V139.23H202.109V123.9H206.812V126.783C207.396 125.85 208.154 125.108 209.078 124.558Z'
        fill='#25358E'
      />
      <path
        d='M219.031 128.749C218.417 129.399 218.11 130.338 218.11 131.566C218.11 132.791 218.417 133.727 219.031 134.368C219.644 135.009 220.401 135.331 221.298 135.331C222.197 135.331 222.949 135.009 223.554 134.368C224.159 133.727 224.46 132.791 224.46 131.566C224.46 130.338 224.159 129.399 223.554 128.749C222.949 128.098 222.197 127.773 221.298 127.773C220.401 127.773 219.644 128.098 219.031 128.749ZM225.381 124.655C226.581 125.285 227.526 126.198 228.214 127.389C228.901 128.58 229.245 129.973 229.245 131.566C229.245 133.159 228.901 134.552 228.214 135.742C227.526 136.933 226.581 137.844 225.381 138.475C224.181 139.108 222.82 139.425 221.298 139.425C219.778 139.425 218.412 139.108 217.202 138.475C215.993 137.844 215.044 136.933 214.356 135.742C213.671 134.552 213.327 133.159 213.327 131.566C213.327 129.973 213.671 128.58 214.356 127.389C215.044 126.198 215.993 125.285 217.202 124.655C218.412 124.021 219.778 123.707 221.298 123.707C222.82 123.707 224.181 124.021 225.381 124.655Z'
        fill='#25358E'
      />
      <path
        d='M239.278 121.811C238.508 121.811 237.947 121.975 237.602 122.307C237.253 122.634 237.059 123.165 237.023 123.9H239.8V127.856H237.023V139.23H232.322V127.856H230.48V123.9H232.322V123.733C232.322 121.829 232.877 120.359 233.986 119.323C235.094 118.289 236.703 117.772 238.811 117.772C239.251 117.772 239.582 117.781 239.8 117.798V121.839L239.278 121.811Z'
        fill='#25358E'
      />
      <path
        d='M250.921 128.171C250.362 127.668 249.669 127.416 248.845 127.416C247.983 127.416 247.268 127.673 246.7 128.186C246.134 128.699 245.812 129.45 245.738 130.439H251.731C251.75 129.432 251.479 128.675 250.921 128.171ZM256.351 132.418H245.711C245.766 133.57 246.058 134.4 246.591 134.904C247.123 135.407 247.799 135.659 248.625 135.659C249.321 135.659 249.898 135.486 250.356 135.137C250.814 134.792 251.117 134.34 251.264 133.79H256.241C256.039 134.871 255.597 135.839 254.921 136.69C254.243 137.542 253.38 138.211 252.336 138.696C251.292 139.181 250.128 139.424 248.845 139.424C247.341 139.424 246.009 139.11 244.845 138.476C243.681 137.845 242.769 136.932 242.109 135.742C241.449 134.551 241.12 133.158 241.12 131.565C241.12 129.954 241.446 128.555 242.095 127.374C242.745 126.192 243.657 125.286 244.83 124.654C246.005 124.022 247.341 123.706 248.845 123.706C250.365 123.706 251.704 124.019 252.858 124.641C254.015 125.262 254.906 126.139 255.539 127.265C256.171 128.392 256.487 129.687 256.487 131.153C256.487 131.539 256.441 131.96 256.351 132.418Z'
        fill='#25358E'
      />
      <path
        d='M269.229 125.162C270.392 126.134 271.12 127.417 271.414 129.01H267.015C266.888 128.387 266.598 127.896 266.149 127.54C265.701 127.181 265.136 127.003 264.458 127.003C263.927 127.003 263.524 127.119 263.25 127.347C262.974 127.576 262.837 127.902 262.837 128.322C262.837 128.798 263.088 129.157 263.594 129.394C264.096 129.634 264.888 129.871 265.971 130.108C267.144 130.384 268.105 130.663 268.857 130.946C269.609 131.231 270.26 131.692 270.809 132.335C271.359 132.975 271.635 133.837 271.635 134.919C271.635 135.797 271.396 136.576 270.918 137.252C270.442 137.93 269.754 138.461 268.857 138.846C267.958 139.231 266.896 139.424 265.668 139.424C263.598 139.424 261.939 138.967 260.691 138.05C259.447 137.135 258.686 135.825 258.41 134.121H262.947C263.02 134.779 263.298 135.285 263.785 135.632C264.271 135.981 264.899 136.154 265.668 136.154C266.201 136.154 266.603 136.031 266.879 135.782C267.153 135.534 267.291 135.211 267.291 134.809C267.291 134.276 267.039 133.895 266.535 133.668C266.03 133.438 265.21 133.197 264.074 132.94C262.938 132.703 262.002 132.444 261.27 132.17C260.537 131.894 259.904 131.45 259.373 130.836C258.84 130.222 258.575 129.384 258.575 128.322C258.575 126.948 259.097 125.836 260.143 124.984C261.188 124.131 262.662 123.707 264.568 123.707C266.511 123.707 268.065 124.194 269.229 125.162Z'
        fill='#25358E'
      />
      <path
        d='M274.301 139.23H279.002V123.899H274.301V139.23ZM274.63 117.813C275.143 117.346 275.821 117.111 276.665 117.111C277.509 117.111 278.185 117.346 278.7 117.813C279.211 118.278 279.47 118.871 279.47 119.584C279.47 120.281 279.211 120.863 278.7 121.33C278.185 121.796 277.509 122.03 276.665 122.03C275.821 122.03 275.143 121.796 274.63 121.33C274.117 120.863 273.862 120.281 273.862 119.584C273.862 118.871 274.117 118.278 274.63 117.813Z'
        fill='#25358E'
      />
      <path
        d='M287.208 128.749C286.594 129.399 286.287 130.338 286.287 131.566C286.287 132.791 286.594 133.727 287.208 134.368C287.822 135.009 288.578 135.331 289.475 135.331C290.374 135.331 291.126 135.009 291.73 134.368C292.335 133.727 292.637 132.791 292.637 131.566C292.637 130.338 292.335 129.399 291.73 128.749C291.126 128.098 290.374 127.773 289.475 127.773C288.578 127.773 287.822 128.098 287.208 128.749ZM293.56 124.655C294.758 125.285 295.703 126.198 296.391 127.389C297.078 128.58 297.422 129.973 297.422 131.566C297.422 133.159 297.078 134.552 296.391 135.742C295.703 136.933 294.758 137.844 293.56 138.475C292.357 139.108 290.997 139.425 289.475 139.425C287.954 139.425 286.589 139.108 285.379 138.475C284.169 137.844 283.223 136.933 282.535 135.742C281.847 134.552 281.504 133.159 281.504 131.566C281.504 129.973 281.847 128.58 282.535 127.389C283.223 126.198 284.169 125.285 285.379 124.655C286.589 124.021 287.954 123.707 289.475 123.707C290.997 123.707 292.357 124.021 293.56 124.655Z'
        fill='#25358E'
      />
      <path
        d='M313.846 125.493C314.881 126.665 315.4 128.277 315.4 130.327V139.232H310.699V130.879C310.699 129.853 310.429 129.05 309.888 128.473C309.348 127.896 308.627 127.608 307.73 127.608C306.778 127.608 306.02 127.909 305.462 128.516C304.903 129.12 304.623 129.98 304.623 131.097V139.232H299.923V123.9H304.623V126.702C305.063 125.787 305.719 125.063 306.588 124.53C307.458 124.001 308.482 123.733 309.655 123.733C311.414 123.733 312.811 124.321 313.846 125.493Z'
        fill='#25358E'
      />
      <path
        d='M323.469 128.817C322.855 129.477 322.548 130.392 322.548 131.566C322.548 132.738 322.855 133.653 323.469 134.313C324.081 134.973 324.892 135.303 325.901 135.303C326.892 135.303 327.702 134.963 328.333 134.285C328.965 133.607 329.283 132.701 329.283 131.566C329.283 130.41 328.965 129.501 328.333 128.832C327.702 128.163 326.892 127.827 325.901 127.827C324.892 127.827 324.081 128.159 323.469 128.817ZM327.414 124.503C328.256 125.034 328.879 125.758 329.283 126.675V123.9H333.956V139.23H329.283V136.455C328.879 137.372 328.256 138.096 327.414 138.627C326.57 139.158 325.563 139.423 324.388 139.423C323.125 139.423 321.993 139.109 320.994 138.475C319.994 137.844 319.207 136.933 318.63 135.742C318.051 134.552 317.764 133.159 317.764 131.566C317.764 129.954 318.051 128.556 318.63 127.375C319.207 126.193 319.994 125.285 320.994 124.655C321.993 124.021 323.125 123.707 324.388 123.707C325.563 123.707 326.57 123.972 327.414 124.503Z'
        fill='#25358E'
      />
      <path
        d='M337.255 139.23H341.956V118.897H337.255V139.23Z'
        fill='#25358E'
      />
      <path
        d='M460.826 63.233C464.637 57.2998 466.846 50.2403 466.846 42.6645V40.1251C466.846 19.0715 449.772 1.99966 428.706 1.99966H360.094V22.3385H440.163C450.695 22.3385 459.234 30.9102 459.234 41.4389C459.234 42.9511 459.054 44.4192 458.719 45.8304C457.761 49.8894 455.502 53.4393 452.404 56.0265C449.285 58.632 445.318 60.2618 440.974 60.4437H360.094V80.7862H428.706C428.87 80.7862 429.03 80.7825 429.192 80.7807H457.94C460.589 80.7807 463.111 81.3246 465.403 82.3021C472.227 85.2163 477.013 92.0002 477.013 99.8811C477.013 110.41 468.473 118.906 457.94 118.906H360.094V139.23H446.485C467.549 139.23 484.625 122.162 484.625 101.109V98.5692C484.625 82.5851 474.783 68.8979 460.826 63.233Z'
        fill='#25358E'
      />
      <path
        d='M47.3291 86.9046C47.3291 84.209 46.4412 82.096 44.6672 80.569C42.8895 79.0439 40.3692 78.2796 37.1043 78.2796H24.2138V95.2099H37.2109C43.9558 95.2099 47.3291 92.4409 47.3291 86.9046ZM24.2138 65.5055H36.1446C42.4649 65.5055 45.625 62.8082 45.625 57.4134C45.625 52.0204 42.3932 49.3212 35.9314 49.3212H24.2138V65.5055ZM61.9752 77.8551C64.639 81.1919 65.97 85.0598 65.97 89.4587C65.97 95.9192 63.8209 100.957 59.5247 104.577C55.2285 108.197 49.0682 110.007 41.0457 110.007H6.00128V34.7355H40.1927C47.8623 34.7355 53.7909 36.4057 57.9805 39.7389C62.1701 43.0757 64.2658 47.831 64.2658 54.0067C64.2658 58.5489 63.058 62.3286 60.6443 65.3457C58.2287 68.361 55.0686 70.3307 51.1639 71.2531C55.7083 72.3188 59.3133 74.5201 61.9752 77.8551Z'
        fill='#25358E'
      />
      <path
        d='M102.559 53.1547C106.144 51.0251 110.03 49.9612 114.224 49.9612V69.4438H109.003C104.173 69.4438 100.429 70.4728 97.7667 72.5307C95.103 74.5905 93.7702 78.067 93.7702 82.9638V110.008H75.5558V50.5988H93.7702V61.7779C96.0442 58.1581 98.9727 55.2843 102.559 53.1547Z'
        fill='#25358E'
      />
      <path
        d='M121.891 110.007H140.106V50.5986H121.891V110.007Z'
        fill='#25358E'
      />
      <path
        d='M192.352 55.4964C196.861 59.2576 199.683 64.2261 200.821 70.4018H183.778C183.28 67.9911 182.162 66.0911 180.421 64.7057C178.68 63.3221 176.498 62.6312 173.871 62.6312C171.812 62.6312 170.251 63.074 169.185 63.9597C168.119 64.849 167.588 66.1077 167.588 67.7393C167.588 69.586 168.562 70.9714 170.516 71.892C172.468 72.8162 175.539 73.7387 179.73 74.6611C184.273 75.7249 188.003 76.809 190.914 77.9078C193.826 79.0103 196.347 80.8 198.477 83.2861C200.608 85.7703 201.672 89.1053 201.672 93.291C201.672 96.6995 200.75 99.7166 198.904 102.342C197.056 104.968 194.393 107.026 190.914 108.518C187.434 110.008 183.315 110.752 178.559 110.752C170.533 110.752 164.106 108.977 159.278 105.429C154.449 101.881 151.502 96.8043 150.438 90.2041H168.012C168.297 92.7582 169.38 94.7132 171.261 96.0619C173.141 97.4088 175.577 98.0831 178.559 98.0831C180.618 98.0831 182.179 97.6054 183.245 96.6462C184.309 95.6889 184.842 94.4284 184.842 92.8666C184.842 90.8086 183.866 89.3368 181.914 88.4475C179.96 87.56 176.781 86.6211 172.38 85.6252C167.976 84.7046 164.356 83.7087 161.516 82.6448C158.674 81.5809 156.225 79.8611 154.166 77.4816C152.105 75.1057 151.076 71.8571 151.076 67.7393C151.076 62.4181 153.1 58.1037 157.148 54.8036C161.196 51.5036 166.911 49.8554 174.298 49.8554C181.824 49.8554 187.843 51.7351 192.352 55.4964Z'
        fill='#25358E'
      />
      <path
        d='M245.45 94.3568V110.008H237.25C230.288 110.008 224.874 108.287 221.004 104.843C217.134 101.402 215.199 95.7055 215.199 87.7566V65.9313H207.211V50.5995H215.199V36.0138H233.415V50.5995H245.344V65.9313H233.415V88.0763C233.415 90.4173 233.893 92.0508 234.852 92.9732C235.81 93.8956 237.424 94.3568 239.7 94.3568H245.45Z'
        fill='#25358E'
      />
      <path
        d='M274.688 69.3905C272.309 71.9115 271.12 75.5478 271.12 80.3031C271.12 85.0603 272.309 88.6801 274.688 91.1644C277.065 93.6486 279.995 94.8889 283.475 94.8889C286.953 94.8889 289.867 93.6486 292.211 91.1644C294.553 88.6801 295.726 85.0603 295.726 80.3031C295.726 75.5478 294.553 71.9115 292.211 69.3905C289.867 66.8713 286.953 65.6108 283.475 65.6108C279.995 65.6108 277.065 66.8713 274.688 69.3905ZM299.294 53.5277C303.945 55.977 307.602 59.5086 310.266 64.1206C312.929 68.7345 314.258 74.1293 314.258 80.3031C314.258 86.477 312.929 91.8736 310.266 96.4856C307.602 101.1 303.945 104.631 299.294 107.08C294.641 109.53 289.369 110.754 283.475 110.754C277.582 110.754 272.293 109.53 267.605 107.08C262.919 104.631 259.244 101.1 256.58 96.4856C253.917 91.8736 252.586 86.477 252.586 80.3031C252.586 74.1293 253.917 68.7345 256.58 64.1206C259.244 59.5086 262.919 55.977 267.605 53.5277C272.293 51.0802 277.582 49.8546 283.475 49.8546C289.369 49.8546 294.641 51.0802 299.294 53.5277Z'
        fill='#25358E'
      />
      <path
        d='M323.738 110.007H341.952V31.2226H323.738V110.007Z'
        fill='#25358E'
      />
      <path
        d='M121.887 40.5477H140.102V22.3422H121.887V40.5477Z'
        fill='#25358E'
      />
      <path
        d='M490.168 132.456C489.967 132.379 489.68 132.339 489.313 132.339H488.954V133.972H489.526C489.872 133.972 490.14 133.904 490.335 133.766C490.526 133.628 490.623 133.406 490.623 133.097C490.623 132.789 490.471 132.576 490.168 132.456ZM487.48 136.976V131.346C487.829 131.346 488.351 131.348 489.048 131.35C489.745 131.352 490.131 131.355 490.208 131.361C490.657 131.392 491.026 131.49 491.32 131.649C491.82 131.925 492.07 132.373 492.07 132.993C492.07 133.467 491.94 133.808 491.675 134.02C491.41 134.229 491.085 134.356 490.699 134.396C491.052 134.47 491.32 134.578 491.5 134.722C491.829 134.99 491.995 135.411 491.995 135.984V136.488C491.995 136.543 491.999 136.598 492.006 136.653C492.013 136.708 492.028 136.763 492.046 136.818L492.1 136.976H490.691C490.647 136.798 490.614 136.539 490.598 136.199C490.583 135.859 490.552 135.629 490.508 135.51C490.434 135.312 490.294 135.174 490.092 135.097C489.982 135.051 489.815 135.021 489.59 135.007L489.265 134.984H488.954V136.976H487.48ZM486.721 131.208C485.912 132.035 485.507 133.017 485.507 134.152C485.507 135.328 485.917 136.328 486.743 137.149C487.559 137.972 488.548 138.384 489.706 138.384C490.861 138.384 491.846 137.968 492.664 137.143C493.482 136.311 493.89 135.313 493.89 134.152C493.89 133.022 493.486 132.041 492.671 131.208C491.848 130.363 490.861 129.941 489.706 129.941C488.539 129.941 487.542 130.363 486.721 131.208ZM493.265 137.798C492.274 138.753 491.09 139.231 489.706 139.231C488.278 139.231 487.072 138.742 486.094 137.763C485.114 136.783 484.623 135.58 484.623 134.152C484.623 132.667 485.153 131.434 486.211 130.451C487.204 129.531 488.368 129.072 489.706 129.072C491.109 129.072 492.306 129.568 493.298 130.562C494.291 131.554 494.789 132.75 494.789 134.152C494.789 135.594 494.28 136.809 493.265 137.798Z'
        fill='#25358E'
      />
    </svg>
  )
}
