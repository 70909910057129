import { useEffect } from 'react'

import theme from 'styles/theme'

export default function useMediaQueryListener(node, setMenuOpen) {
  useEffect(() => {
    const mql = window.matchMedia(`(${theme.max848})`)

    const handleEventClick = event => {
      if (node.current === event.target) return
      setMenuOpen(false)
    }

    const handleMobileEvent = () => {
      if (mql.matches) {
        window.addEventListener('mousedown', handleEventClick)
      } else {
        window.removeEventListener('mousedown', handleEventClick)
      }
    }

    handleMobileEvent(mql)

    mql.addEventListener('change', handleMobileEvent)

    return () => {
      mql.removeEventListener('change', handleMobileEvent)
    }
  }, [node, setMenuOpen])
}
