import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import loadable from '@loadable/component'

import { Container } from 'styles/CssHelpers'
import theme from 'styles/theme'
import FooterNavigation from './FooterNavigation'
import GoogleReview from './GoogleReview'
import Address from './Address'

const SvgCambridgeLogo = loadable(
  () => import('components/Svg/SvgCambridgeLogo'),
)
const SvgFooterBrand = loadable(() => import('components/Svg/SvgFooterBrand'))
const Newsletter = loadable(() => import('./Newsletter'))

const siteNavigation = [
  {
    url: '/',
    name: 'Home',
    order: '1',
  },
  {
    url: '/nosotros',
    name: 'Nosotros',
    order: '3',
  },
  {
    url: '/cursos',
    name: 'Inglés General',
    order: '2',
  },
  {
    url: '/educacion-continua',
    name: 'Educación Continua',
    order: '5',
  },
  {
    url: '/examenes',
    name: 'Exámenes Internacionales',
    order: '4',
  },
  {
    url: '/centro-examinador-cambridge',
    name: 'Centro Examinador Cambridge',
    order: '6',
  },
  {
    url: '/aviso-privacidad',
    name: 'Aviso de Privacidad',
    order: '7',
  },
]

export default function Footer() {
  const { contentfulMetadata: data } = useStaticQuery(graphql`
    query FOOTER_QUERY {
      contentfulMetadata(identifier: { eq: "metadata" }) {
        COMMODITY_AESTHETICS
        LASSO
        GOOGLE_REVIEW
      }
    }
  `)

  return (
    <>
      <Newsletter />
      <footer css={footerStyles}>
        <Container css={containerStyles}>
          <div css={footerBrandsStyles}>
            <SvgFooterBrand fill='white' />
            <SvgCambridgeLogo fill='white' />
          </div>
          <FooterNavigation siteNavigation={siteNavigation} />
          <GoogleReview data={data} />
        </Container>
      </footer>
      <Address data={data} />
    </>
  )
}

const footerStyles = css`
  background-color: ${theme.bristolBlue};
  padding-top: ${theme.newsletterOffset};
  min-height: 1000px;

  @media (${theme.min960}) {
    min-height: 500px;
  }

  * {
    color: white;
  }
`
const containerStyles = css`
  padding-bottom: 2rem;

  @media (${theme.min960}) {
    display: flex;
  }

  @media (${theme.min1120}) {
    align-items: flex-end;
  }
`
const footerBrandsStyles = css`
  margin: 3rem ${theme.gutter};
  display: inline-block;
  max-width: 360px;
  width: 72vw;

  @media (${theme.min640}) {
    margin-right: 0;
    padding-right: 5rem;
  }

  @media (${theme.min960}) {
    margin-left: 0;
    padding-right: 5rem;
  }

  svg {
    &:first-of-type {
      margin-bottom: 2rem;
      width: 100%;

      + svg {
        width: 58%;
      }
    }
  }
`
