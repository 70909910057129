import React from 'react'

export default function SvgPhone({ fill }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91049 2.88855C9.91049 2.69207 10.0675 2.53261 10.2618 2.53261H12.5931C12.787 2.53261 12.9444 2.69207 12.9444 2.88855C12.9444 3.08502 12.787 3.24448 12.5931 3.24448H10.2618C10.0675 3.24448 9.91049 3.08502 9.91049 2.88855ZM12.5207 19.9733C12.5207 20.1698 12.3633 20.3292 12.1697 20.3292H10.6859C10.4916 20.3292 10.3346 20.1701 10.3346 19.9733C10.3346 19.7765 10.4916 19.6174 10.6859 19.6174H12.1693C12.3629 19.6174 12.5203 19.7768 12.5207 19.9733ZM13.82 3.02416C13.8003 2.9818 13.7908 2.93553 13.7919 2.88855C13.7926 2.79422 13.8291 2.70382 13.8938 2.63583C14.035 2.50414 14.2518 2.50414 14.3927 2.63583C14.4577 2.70346 14.4939 2.79422 14.4946 2.88855C14.4942 2.93517 14.4847 2.98144 14.4665 3.02416C14.4496 3.06651 14.4246 3.10495 14.3927 3.1377C14.2939 3.2427 14.1411 3.27402 14.0097 3.21636C13.9665 3.19821 13.9271 3.17116 13.8938 3.1377C13.8621 3.10495 13.8372 3.06651 13.82 3.02416ZM16.0981 21.129H6.75676C6.17492 21.1283 5.70305 20.6506 5.7027 20.0612V18.7962H17.1522V20.0612C17.1515 20.6506 16.68 21.1283 16.0981 21.129ZM6.75676 1.71183H16.0981C16.68 1.71255 17.1515 2.19021 17.1522 2.77963V4.04461H5.7027V2.77963C5.70305 2.19021 6.17492 1.71255 6.75676 1.71183ZM5.7027 18.0844H17.1522V4.75648H5.7027V18.0844ZM16.0981 0.999969H6.75676C5.78703 1.00139 5.00105 1.79726 5 2.77963V20.0612C5.00105 21.0436 5.78703 21.8398 6.75676 21.8409H16.0981C17.0679 21.8398 17.8538 21.0436 17.8549 20.0612V2.77963C17.8538 1.79726 17.0679 1.00139 16.0981 0.999969Z'
        fill={fill}
      />
    </svg>
  )
}
