exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-privacidad-js": () => import("./../../../src/pages/aviso-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-privacidad-js" */),
  "component---src-pages-centro-examinador-cambridge-js": () => import("./../../../src/pages/centro-examinador-cambridge.js" /* webpackChunkName: "component---src-pages-centro-examinador-cambridge-js" */),
  "component---src-pages-cursos-js": () => import("./../../../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-educacion-continua-js": () => import("./../../../src/pages/educacion-continua.js" /* webpackChunkName: "component---src-pages-educacion-continua-js" */),
  "component---src-pages-examenes-js": () => import("./../../../src/pages/examenes.js" /* webpackChunkName: "component---src-pages-examenes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-templates-course-details-index-js": () => import("./../../../src/templates/CourseDetails/index.js" /* webpackChunkName: "component---src-templates-course-details-index-js" */)
}

