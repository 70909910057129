import React from 'react'
import { css } from '@emotion/react'

import theme from 'styles/theme'
import SocialLinks from 'components/common/SocialLinks'

export default function Address({ data }) {
  return (
    <div css={addressBox}>
      <address css={addressStyles}>
        <span>Bristol Inglés Profesional ©{new Date().getFullYear()}</span>
        <span>
          Made by&nbsp;
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={data.COMMODITY_AESTHETICS}
          >
            Commodity Aesthetics
          </a>{' '}
          &amp;{' '}
          <a target='_blank' rel='noreferrer' href={data.LASSO}>
            Luis Lasso
          </a>
        </span>
      </address>
      <SocialLinks css={socialLinksStyles} />
    </div>
  )
}

const addressBox = css`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: start;
  padding: 0.4rem calc(${theme.gutter} + 2.5rem) 0.4rem ${theme.gutter};
  background-color: ${theme.blueLight};
  gap: 1rem;
  position: relative;
  overflow: hidden;

  @media (${theme.min560}) {
    align-items: flex-end;
    justify-content: space-between;
  }

  @media (${theme.min960}) {
    align-items: center;
  }

  @media (${theme.min1400}) {
    padding-right: ${theme.gutter};
  }
`
const addressStyles = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  height: 4rem;
  color: white;
  margin-inline-start: ${theme.gutter};
  gap: 0.5rem;

  @media (${theme.max480}) {
    margin-bottom: 1rem;
  }

  @media (${theme.min560}) and (${theme.max960}) {
    flex-direction: column;
  }

  @media (${theme.min960}) {
    margin-inline-start: 0;
    height: auto;
    justify-content: space-between;
    flex-grow: 1;

    > span:last-of-type {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  span {
    display: block;
  }

  a {
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
`
const socialLinksStyles = css`
  a {
    padding: 1.2rem;
  }

  svg {
    fill: white;
    width: 1.2rem;
  }
`
