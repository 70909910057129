import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import theme from './theme'

const borderBottomStyle = `border-bottom: 1px solid ${theme.gray};`

export const Main = styled.main`
  min-height: 100vh;
  overflow: hidden;
  background-color: ${theme.bgGray};
  padding-bottom: ${parseInt(theme.newsletterOffset.split('rem')) + 4 + 'rem'};
  margin-bottom: -${theme.newsletterOffset};

  > section + div {
    min-height: 100vh;
  }
`
export const Section = styled.section`
  background-color: white;

  &.gray {
    background-color: ${theme.bgGray};
  }

  &.borderBottom {
    ${borderBottomStyle}
  }
`
export const Container = styled.section`
  margin-left: ${theme.gutter};
  margin-right: ${theme.gutter};
  padding-top: 3rem;
  padding-bottom: 3rem;

  &.paddingBig,
  &.paddingBigTop {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &.paddingSmall {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &.noPadding {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (${theme.min1200}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 70rem;

    &.slim {
      max-width: 58rem;
    }

    &.thick {
      max-width: 75rem;
    }
  }
`
export function ContainerWhite({ children, borderBottom = false }) {
  return (
    <section
      css={css`
        background-color: white;
        overflow: hidden; //possible svg overflow on index
        ${borderBottom && borderBottomStyle}
      `}
    >
      <Container as='div'>{children}</Container>
    </section>
  )
}
export const title = css`
  text-align: center;
  transition: color 0.24s ease-in;
  margin-top: 4rem;
  &:hover {
    color: ${theme.black};
  }
`
export const Button = styled.a`
  font-size: 1.2rem;
  padding: 1rem 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: ${theme.radius};
  background-color: ${theme.bristolBlue};

  &.noBg {
    color: ${theme.black};
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  &.noRadius {
    border-radius: 0;
  }

  &.widthFull {
    width: 100%;
  }

  &.red {
    background-color: ${theme.bristolRed};
  }

  &.blue {
    &:hover {
      background-color: ${theme.bristolRed};
    }
  }

  &:hover {
    background-color: ${theme.pink};
  }
`
export const hoverStyle = (color = false) => {
  return `
    &:hover {
      background-color: ${theme.bgGray};
      color: ${color ? color : theme.grayDark};
    }
  `
}

export const borderTopStyle = color => `border-top: 1px solid ${color};`

export const Anchor = styled(Link)`
  color: ${theme.bristolBlue};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.75rem;
    width: 1.5rem;
  }

  & + & {
    margin-top: 0.5rem;
  }
`
export const Select = styled.select`
  display: block;
  font-size: 16px;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  appearance: none;
  background-color: white;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  border: 1px solid ${theme.gray};

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: ${theme.bristolBlue};
  }
  &:focus {
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
    border-color: ${theme.bristolBlue};
  }
`
export const Feature = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.alignTop {
    align-items: flex-start;
  }

  svg {
    flex-basis: 4.25rem;
    margin-right: 2rem;
  }
  p {
    margin-bottom: 0;
    flex: 1;
  }
  @media (${theme.max480}) {
    svg {
      flex-basis: 16.192vw;
      margin-right: 8vw;
    }
  }
`
