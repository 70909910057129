import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'
import { FaWhatsapp } from 'react-icons/fa'

import theme from 'styles/theme'
import { Button } from 'styles/CssHelpers'

export default function WhatsAppLink({
  text,
  cssStyles,
  isCta = false,
  isButton = false,
}) {
  const { contentfulMetadata: data } = useStaticQuery(graphql`
    query WHATSAPP_LINK {
      contentfulMetadata(identifier: { eq: "metadata" }) {
        WHATSAPP
        CTA
      }
    }
  `)

  const href = `https://wa.me/52${data.WHATSAPP}`
  const content = isCta ? data.CTA : <FaWhatsapp />
  const styles = cssStyles ?? {}

  return isButton ? (
    <Button css={styles} href={href} target='_blank' rel='noreferrer'>
      {text ?? content}
    </Button>
  ) : (
    <a
      css={(!isCta && whatsAppStyles) || styles}
      href={href}
      target='_blank'
      rel='noreferrer'
      aria-label='Chatea con nosotros'
    >
      {text ?? content}
    </a>
  )
}

const whatsAppStyles = css`
  --s: 44px;
  --p: 0.5rem;
  --c: #25d366;

  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--c);
  border-radius: 20%;
  position: fixed;
  height: var(--s);
  width: var(--s);
  bottom: var(--p);
  right: var(--p);
  z-index: 5;

  svg {
    fill: white;
    width: 80%;
    transform: translateX(5%);
  }

  @media (${theme.min960}) {
    --s: 52px;
    --p: 1rem;

    svg {
      transform: translateX(2%);
    }
  }

  @media (${theme.min1400}) {
    --s: 60px;
    --p: 4rem;
  }
`
