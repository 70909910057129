import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { AiOutlineMenu } from 'react-icons/ai'

import useMediaQueryListener from 'utils/useMediaQueryListener'
import { Button } from 'styles/CssHelpers'
import theme from 'styles/theme'

export default function MenuButton({ menuOpen, setMenuOpen }) {
  const node = useRef()
  useMediaQueryListener(node, setMenuOpen)

  return (
    <MenuButtonStyles
      as='button'
      className='noBg noRadius'
      ref={node}
      onClick={() => setMenuOpen(!menuOpen)}
      id='menubutton'
      aria-label='Menú'
    >
      <AiOutlineMenu />
      &ensp;Menú
    </MenuButtonStyles>
  )
}

const MenuButtonStyles = styled(Button)`
  padding-left: 1.6rem;
  padding-right: 1.6em;

  @media (${theme.min848}) {
    display: none;
  }
`
