import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa'

export default function SocialLinks({ className }) {
  const { contentfulMetadata: data } = useStaticQuery(graphql`
    query SOCIAL_LINKS_QUERY {
      contentfulMetadata(identifier: { eq: "metadata" }) {
        FACEBOOK
        INSTAGRAM
        WHATSAPP
      }
    }
  `)

  return (
    <List className={className}>
      <SocialLink domain='WhatsApp' url={`https://wa.me/52${data.WHATSAPP}`}>
        <FaWhatsapp />
      </SocialLink>
      <SocialLink domain='Facebook' url={data.FACEBOOK}>
        <FaFacebookF />
      </SocialLink>
      <SocialLink domain='Instagram' url={data.INSTAGRAM}>
        <FaInstagram />
      </SocialLink>
    </List>
  )
}

function SocialLink({ children, domain, url }) {
  return (
    <li>
      <a
        target='_blank'
        rel='noreferrer'
        href={url}
        aria-label={`Página de ${domain}`}
      >
        {children}
      </a>
    </li>
  )
}

const List = props => (
  <ul
    className={props.className}
    css={css`
      display: flex;
      a {
        padding: 0 0.7rem;
        display: block;
        height: 2.4rem;
        display: inline-flex;
        align-items: center;
      }
    `}
    {...props}
  />
)
