import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import Logo from './Logo'
import Navigation from './Navigation'
import ContactInfo from './ContactInfo'
import SocialLinksList from './SocialLinksList'
import Slogan from './Slogan'
import ButtonsBox from './ButtonsBox'
import MenuButton from './MenuButton'

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)

  const { contentfulMetadata: data } = useStaticQuery(graphql`
    query CONTACT_INFO_QUERY {
      contentfulMetadata(identifier: { eq: "metadata" }) {
        EMAIL
        TEL_XALAPA_1
        TEL_XALAPA_2
        TEL_COATEPEC
      }
    }
  `)

  return (
    <HeaderBox>
      <Logo />
      <Navigation menuOpen={menuOpen} />
      <ContactInfo data={data} />
      <SocialLinksList />
      <Slogan />
      <ButtonsBox data={data}>
        <MenuButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      </ButtonsBox>
    </HeaderBox>
  )
}

const HeaderBox = styled.header`
  display: grid;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  box-shadow: 0 1px 2px hsla(220, 11%, 15%, 0.15);
  grid-template-columns: max-content 1fr max-content max-content;
  background-color: white;

  > div,
  > ul {
    background-color: white;
  }
`
