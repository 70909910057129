import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { AiOutlineRight } from 'react-icons/ai'

import theme from 'styles/theme'

export default function FooterNavigation({ siteNavigation }) {
  return (
    <div css={footerNavigation}>
      <h1>Mapa del sitio</h1>
      <ul css={footerNavigationList}>
        {siteNavigation.map(({ url, name, order }) => (
          <li css={liStyles} key={order} data-order={order}>
            <Link to={url}>
              {name}
              <AiOutlineRight />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const gap = '8vw'

const liStyles = css`
  svg {
    display: none;
  }

  @media (${theme.min848}) {
    order: attr(data-order);
    flex-grow: 1;
  }

  @media (${theme.max720}) {
    a {
      position: relative;
    }

    svg {
      display: block;
      position: absolute;
      width: 1.25rem;
      right: 1.25rem;
      font-size: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`
const footerNavigation = css`
  padding-bottom: ${theme.gutter};
  margin: 2rem -4vw 0;

  li:first-of-type a {
    border-top: none;
  }

  a {
    display: block;
    border-top: 1px solid white;
    padding: 1rem ${gap};
  }

  h1 {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    padding-left: ${gap};
  }

  @media (${theme.min480}) {
    margin: 0;

    a {
      padding: ${theme.gutter};
    }

    h1 {
      padding-left: ${theme.gutter};
    }
  }

  @media (${theme.min640}) {
    padding-top: 3rem;
    flex-grow: 1;

    a {
      padding-block: 1.4rem;
    }
  }

  @media (${theme.min960}) {
    flex: 1;
    padding-bottom: 0;
    margin-bottom: 3rem;

    h1 {
      padding-left: 0;
    }

    a {
      padding: 0.2rem 0;
      border-top: none;
      width: 11rem;
      border-top: none;
      white-space: nowrap;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }
`
const footerNavigationList = css`
  @media (${theme.min960}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0.2rem;
  }

  @media (${theme.min1120}) {
    flex-direction: row;
    max-width: 460px;
  }
`
