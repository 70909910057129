import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { formatPhone } from 'utils/helpers'
import { hoverStyle } from 'styles/CssHelpers'
import theme from 'styles/theme'
import SvgEnvelope from 'components/Svg/SvgEnvelope'
import SvgPhone from 'components/Svg/SvgPhone'

const color = theme.pink

export default function ContactInfo({ data }) {
  return (
    <ContactBox>
      <ContactLi>
        <ContactAnchor href={`mailto:${data.EMAIL}`}>
          <SvgEnvelope fill={color} />
          <span>Email</span>
          <span>{data.EMAIL}</span>
        </ContactAnchor>
      </ContactLi>
      <ContactLi>
        <ContactAnchor
          href={`tel:${data.TEL_XALAPA_1}`}
          css={css`
            @media (${secondPhoneMediaQuery}) {
              padding-right: 0.25rem;
              justify-content: flex-end;
            }
          `}
        >
          <SvgPhone fill={color} />
          <span>Xalapa</span>
          <span>{formatPhone(data.TEL_XALAPA_1)}</span>
        </ContactAnchor>
        <SpanY
          className='y'
          css={css`
            display: none;
            @media (${secondPhoneMediaQuery}) {
              display: inherit;
            }
          `}
        >
          y
        </SpanY>
        <ContactAnchor
          href={`tel:${data.TEL_XALAPA_2}`}
          css={css`
            display: none;
            flex-grow: 2;
            justify-content: flex-start;
            padding-left: 0.5rem;
            span {
              margin-right: 0.3rem;
            }
            @media (${secondPhoneMediaQuery}) {
              display: inherit;
            }
          `}
        >
          <span className='phone'>{formatPhone(data.TEL_XALAPA_2)}</span>
        </ContactAnchor>
      </ContactLi>
      <ContactLi>
        <ContactAnchor href={`tel:${data.TEL_COATEPEC}`}>
          <SvgPhone fill={color} />
          <span>Coatepec</span>
          <span>{formatPhone(data.TEL_COATEPEC)}</span>
        </ContactAnchor>
      </ContactLi>
    </ContactBox>
  )
}

const secondPhoneMediaQuery = 'min-width: 72em'

const ContactBox = styled.ul`
  display: grid;
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border-top: 1px solid ${theme.grayLight};
  border-bottom: 1px solid ${theme.grayLight};

  @media (${theme.min848}) {
    border-top: none;
    border-bottom: none;
    grid-row: 1 / 2;
    grid-column: 2 / -2;
  }
  @media (${theme.min960}) {
    grid-template-columns: repeat(3, minmax(208px, 256px));
  }
  @media (${secondPhoneMediaQuery}) {
    grid-template-columns: minmax(208px, 256px) minmax(208px, 320px) minmax(
        208px,
        256px
      );
  }
`
const ContactLi = styled.li`
  display: flex;
  justify-content: center;

  a {
    span {
      &:last-of-type {
        display: none;
      }
      @media (${theme.min640}) {
        &:last-of-type {
          display: initial;
        }
        &:first-of-type:not(.phone) {
          margin-right: 0.7rem;
        }
      }
    }
  }
  &:first-of-type {
    border-right: 1px solid ${theme.grayLight};

    @media (max-width: 18.9375em) {
      display: none;
    }
    @media (${theme.min640}) {
      span {
        &:first-of-type {
          display: none;
        }
      }
    }
  }
  &:last-of-type {
    border-left: 1px solid ${theme.grayLight};

    @media (${theme.min848}) {
      border-right: 1px solid ${theme.grayLight};
    }
  }
`
const SpanY = styled.span`
  font-size: 0.875rem;
  margin: 0.67rem 0.25rem 0;

  @media (${theme.min848}) and (${theme.max1040}) {
    font-size: 0.85rem;
  }
`
const ContactAnchor = styled.a`
  height: 2.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  ${hoverStyle()}

  svg {
    margin-right: 0.5em;
  }

  span {
    white-space: nowrap;
  }

  @media (${theme.min848}) and (${theme.max1040}) {
    font-size: 0.85rem;

    svg {
      display: none;
    }
  }
`
