import React from 'react'
import styled from '@emotion/styled'

import { Button } from 'styles/CssHelpers'
import theme from 'styles/theme'

export default function ButtonsBox({ children, data }) {
  return (
    <ButtonsBoxStyles>
      <ContactanosButton className='noRadius' href={`mailto:${data.EMAIL}`}>
        Contáctanos
      </ContactanosButton>
      {children}
    </ButtonsBoxStyles>
  )
}

const ButtonsBoxStyles = styled.div`
  grid-column-end: -1;
  justify-self: end;
`
const ContactanosButton = styled(Button)`
  display: none;

  @media (${theme.min848}) {
    padding: 0rem 1.2rem;
    height: 100%;
    display: flex;
  }
  @media (${theme.min1400}) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
`
